
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/iad/kaufen-und-verkaufen/marktplatz/[[...seopath]]",
      function () {
        return require("private-next-pages/iad/kaufen-und-verkaufen/marktplatz/[[...seopath]].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/iad/kaufen-und-verkaufen/marktplatz/[[...seopath]]"])
      });
    }
  